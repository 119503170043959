import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import {
    Box,
    CardMedia,
    Container,
    Divider,
    Fab,
    IconButton,
    Modal,
    useScrollTrigger,
} from '@material-ui/core';
import styles from './menu1.module.scss';
import useScreenSize from '../../../hooks/useScreenSize';

export interface IMenu1Props {
    bgMenu: string;
    menuList: [
        {
            title: string;
            link: string;
            subcategories: [
                {
                    title: string;
                    link: string;
                },
            ];
        },
    ];
}

const Menu1 = ({ menuList, bgMenu }: IMenu1Props) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
    });

    const [isHovered, setIsHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [optionClick, setOptionClick] = useState(false);
    const [selected, setSelected] = useState(-1);
    const screenSize = useScreenSize();

    // Desktop
    function handleMouseEnter(index) {
        setIsHovered(true);
        setSelected(index);
    }
    function handleMouseLeave() {
        setIsHovered(false);
        setSelected(-1);
    }

    //Mobile
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    function handleOptionClick(index) {
        setSelected(index);
        setOptionClick(!optionClick);
    }

    return (
        <>
            {screenSize > 968 ? (
                <AppBar
                    elevation={trigger ? 0 : 2}
                    className={styles.o_menu1}
                    style={{
                        transform: trigger
                            ? 'translateY(-100%)'
                            : 'translateY(70px)',
                        transition: 'all 0.5s ease',
                        zIndex: 0,
                    }}
                >
                    <Toolbar className={styles.o_menu1__container}>
                        {menuList.map((category, index) => (
                            <Button
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                                key={index}
                                href={category.link}
                                className={styles.o_menu1__category}
                            >
                                <i className="icon-chevron-right1" />
                                {category.title}
                                {isHovered && (
                                    <div
                                        className={styles.o_menu1__column}
                                        key={index}
                                    >
                                        {category.subcategories &&
                                            index === selected &&
                                            category.subcategories.map(
                                                (subcategory, index) => (
                                                    <Button
                                                        key={index}
                                                        href={subcategory.link}
                                                        className={
                                                            styles.o_menu1__category
                                                        }
                                                    >
                                                        <i className="icon-chevron-right1" />
                                                        {subcategory.title}
                                                    </Button>
                                                ),
                                            )}
                                    </div>
                                )}
                            </Button>
                        ))}
                    </Toolbar>
                </AppBar>
            ) : (
                <div className={styles.o_menu1_mobile}>
                    <Fab
                        aria-label="abrir menu"
                        onClick={handleOpen}
                        className={styles.o_menu1_mobile__burger}
                    >
                        <i className="icon-menu-hamburger" />
                    </Fab>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        className={styles.o_menu1_mobile__modal}
                    >
                        <>
                            {bgMenu && (
                                <CardMedia
                                    component="img"
                                    image={bgMenu}
                                    alt=""
                                    className={styles.o_menu1_mobile__bg}
                                />
                            )}
                            <Box className={styles.o_menu1_mobile__content}>
                                <AppBar
                                    position="static"
                                    color="transparent"
                                    elevation={0}
                                >
                                    <Toolbar>
                                        <IconButton
                                            className={
                                                styles.o_menu1_mobile__burger
                                            }
                                            onClick={handleClose}
                                            aria-label="close"
                                        >
                                            <i className="icon-close" />
                                        </IconButton>
                                    </Toolbar>
                                    <Divider />
                                </AppBar>
                                {menuList.map((category, index) => (
                                    <Fab
                                        key={index}
                                        aria-label={category.title}
                                        onClick={() => handleOptionClick(index)}
                                        className={
                                            optionClick && index === selected
                                                ? styles.o_menu1_mobile__category__open
                                                : styles.o_menu1_mobile__category
                                        }
                                    >
                                        <Container
                                            component="div"
                                            className={
                                                styles.o_menu1_mobile__label
                                            }
                                        >
                                            {category.title}
                                            <i className="icon-chevron-down1" />
                                        </Container>
                                        {optionClick && index === selected && (
                                            <Container
                                                component="div"
                                                className={
                                                    styles.o_menu1_mobile__options
                                                }
                                            >
                                                <Button href={category.link}>
                                                    <i className="icon-chevron-right1" />
                                                    {category.title}
                                                </Button>
                                                {category.subcategories?.map(
                                                    (subcategory, index) => (
                                                        <Button
                                                            type="a"
                                                            key={index}
                                                            href={
                                                                subcategory.link
                                                            }
                                                        >
                                                            <i className="icon-chevron-right1" />
                                                            {subcategory.title}
                                                        </Button>
                                                    ),
                                                )}
                                            </Container>
                                        )}
                                    </Fab>
                                ))}
                            </Box>
                        </>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default Menu1;
