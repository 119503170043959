import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '@material-ui/core/Container';
import CardsOverflow from '../components/organisms/cards-overflow/cardsOverflow';
import cardsOverflowAdapter from '../components/organisms/cards-overflow/adapter/cardsOverflow.adapter';
import CardYouTube from '../components/organisms/card-youtube/cardYouTube';
import cardYouTubeAdapter from '../components/organisms/card-youtube/adapter/cardYouTube.adapter';
import Stories from '../components/organisms/stories/stories';
import storiesAdapter from '../components/organisms/stories/adapter/stories.adapter';
import ContentRotator from '../components/molecules/content-rotator/contentRotator';
import CardsConocer from '../components/organisms/cards-conocer/cardsConocer';
import cardsConocerAdapter from '../components/organisms/cards-conocer/adapter/cardsConocer.adapter';
import Layout from '../components/organisms/layout/layout';
import Menu1 from '../components/organisms/menu1/menu1';
import menu1Adapter from '../components/organisms/menu1/adapter/menu1.adapter';
import { makeStyles } from '@material-ui/core';
import SEO from '../shared/seo';

const TemplateAsamblea = (props: any) => {
    const useStyles = makeStyles(() => ({
        container: {
            '@media (max-width: 600px)': {
                padding: '0px !important',
                backgroundColor: 'transparent',
            },
        },
    }));

    const classes = useStyles();

    const dataMenu = useStaticQuery(graphql`
        query MENU_INFORME2023 {
            allContentfulMenuCategorias(
                filter: { contentful_id: { eq: "7gkqGuzPrZj9wahtIuSh4p" } }
            ) {
                nodes {
                    categorias {
                        nombreCategoria
                        hyperLink
                        referenciaSubcategorias {
                            nombreSubcategoria
                            link
                        }
                    }
                    listaDerecha {
                        imagenDestacada {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const menuCategories =
        dataMenu.allContentfulMenuCategorias.nodes[0].categorias;
    const bgMenu =
        dataMenu.allContentfulMenuCategorias.nodes[0].listaDerecha[0]
            .imagenDestacada[0].file.url;

    return (
        <Layout
            hideHeaderDesktop={false}
            hideHeaderMobile
            isNotRoot
            hideSidebar
            headboard={props.pageContext.referenciaCabecera}
            hideBurger
        >
            <SEO
                title={
                    props.pageContext.metaTitulo ||
                    props.pageContext.nombreCategoria
                }
                image={
                    props.pageContext.referenciaCabecera.imagenHeader.file.url
                }
                slug={props.location.pathname}
                description={
                    props.pageContext.metaDescripcion &&
                    props.pageContext.metaDescripcion.json.content[0].content[0]
                        .value
                }
                lang={'es'}
                noCanonical={props.pageContext.noCanonical}
                urlCanonical={props.pageContext.urlCanonical}
                noIndex={props.pageContext.noIndex}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            <Menu1 {...menu1Adapter(menuCategories, bgMenu)} />
            <Container component="section">
                <CardYouTube
                    {...cardYouTubeAdapter(
                        props.pageContext.referenciaContenido[0],
                    )}
                />
            </Container>
            <Container component="section">
                <CardsOverflow
                    {...cardsOverflowAdapter(
                        props.pageContext.referenciaContenido[1]
                            .referenciaPlantillaContenido,
                    )}
                />
            </Container>
            <Container component="section" className={classes.container}>
                <Stories
                    {...storiesAdapter(
                        props.pageContext.referenciaContenido[2],
                    )}
                />
            </Container>
            <Container component="section" style={{ paddingBottom: '60px' }}>
                <ContentRotator
                    {...props.pageContext.referenciaContenido[3]}
                    nombreCategoria={
                        props.pageContext.referenciaContenido[2].tituloGeneral
                    }
                />
            </Container>
            <Container component="section">
                <CardsConocer
                    {...cardsConocerAdapter(
                        props.pageContext.referenciaContenido[4],
                    )}
                />
            </Container>
        </Layout>
    );
};

export default TemplateAsamblea;
