import { IMenu1Props } from '../menu1';

function menu1Adapter(menuCategories: any, bgMenu?: any): IMenu1Props {
    return {
        bgMenu: bgMenu ? bgMenu : '',
        menuList:
            menuCategories?.map((category) => ({
                title: category.nombreCategoria,
                link: category.hyperLink,
                subcategories: category.referenciaSubcategorias?.map(
                    (subcategory) => ({
                        title: subcategory.nombreSubcategoria,
                        link: subcategory.link,
                    }),
                ),
            })) ?? [],
    };
}

export default menu1Adapter;
